import React, { useState,useEffect,useContext } from 'react';

import { Link, Route } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import { incrementLocalCredits as increment } from './functions/incrementLocalCredits.jsx';
import { Container } from 'react-bootstrap';
import Contact from './Contact';
import "./Home.css";
import storeActivity from './functions/storeActivity.jsx';
import CareerGeniusLogo from './assets/CareerGeniusLogo.png'
import AIBuilderCards from './components/AIBuilderCards.jsx'
import AIBuilderCardsMobile from './components/AIBuilderCardsMobile.jsx'
import useWindowDimensions from './functions/useWindowDimensions.jsx'

import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';

import ApplePay from './ApplePay';


const Home = () => {
    const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY
    const { auth,setAuth } = useContext(AuthContext);
    const [localCredits, setLocalCredits] = useState(0);
    const { height, width } = useWindowDimensions();

    const [username, setUsername] = useState('');
    const [credits, setCredits] = useState(0);
    const [loggedIn, setLoggedIn] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [email, setEmail] = useState('');
    const [user_role_type, setUser_role_type] = useState('');

    const [isSubscribed, setIsSubscribed] = useState(false);




    const sendFacebookPageViewEvent = async (email) => {
      try {
        const response = await axios.post('/facebook-server-side-event', {
          event_name: "PageView", // Event name for a page view
          email: email || null, // Optional email (hashed server-side), can be null for anonymous users
        });
        console.log('Page view event sent successfully:', response.data);
      } catch (error) {
        console.error('Failed to send page view event:', error);
      }
    };
    useEffect(() => {         
      //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
      if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
        setIsSubscribed(true);
        }
    }, []);


    useEffect(() => {         
      //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
      try {
      
        axios.post('/false-login', {username: auth.username, sessionId: auth.sessionId}, 
        {
          headers: {'Content-Type': 'application/x-www-form-urlencoded',},
          withCredentials: true // This sends the cookie with the request
        })
          .then((response) => {
            //console.log(response.status);
            // Redirect to homepage or show success message
            if(response.status ==200){
            const { user } = response.data;  
            sessionStorage.setItem('user', JSON.stringify(user));
            setUsername(response.data.username);
            setCredits(response.data.credits);
            setSessionId(response.data.sessionId);
            setEmail(response.data.email);
            setLoggedIn("1");
            setUser_role_type(response.data.user_role_type);
            localStorage.setItem('username', response.data.username);
            localStorage.setItem('credits', response.data.credits);
            localStorage.setItem('loggedIn', 1);
            localStorage.setItem('sessionId', response.data.sessionId);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('user_role_type', response.data.user_role_type);
            localStorage.setItem('isVerified', response.data.isVerified);
            setAuth({ username:response.data.username, credits:response.data.credits, loggedIn:1,sessionId:response.data.sessionId 
              ,email:response.data.email,user_role_type:response.data.user_role_type, isVerified:response.data.isVerified });
           
     
            }
          })
          .catch((error) => {
  
          });
  
  
      } catch (error) {
        console.log(error);
  
      }
    }, []);

    const incrementLocalCredits = async () => {
        const creditsToken = localStorage.getItem('localcreditstoken142023342');
      const result = increment(creditsToken);
      if(result != 1){
      setLocalCredits(localCredits + 1);
      }
      //window.location.replace("/");
      };

      useEffect(() => {         
        //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
        storeActivity( auth.sessionId       ,"Page Landing"     ,'Home'             ,'Page'              ,'Landed on Home page');

        // Example usage:
        sendFacebookPageViewEvent(auth.email); // Call this on page load or landing

      }, []);

      
     

  return (
  
    <div style={{ textAlign: 'center',
     padding: '20px',
     marginLeft: width > 700 ? '20%' : '0',
     marginRight: width > 700 ? '20%' : '0' }}>
     

      <h1> <img src={CareerGeniusLogo} alt="CareerGeniusLogo" className="careerGeniusLogoImg"  /></h1>
      
            {auth.loggedIn!=1 ? 
                    <div>
                      <h3>Login Or Sign Up</h3>
                      <br/>
                      <Link to="/loginform" className="btn btn-primary mr-2">Login</Link>
                      <Link to="/signupform" className="btn btn-dark mr-2">Sign Up</Link>
                    </div> 
                    :
                  <div>     
                      {auth.isVerified != 1 ? 
                       <div> 
                            <h3 className="mx-auto text-center" style={{ color: "red" }}>Your email is not verified</h3>
                            <p className="mx-auto text-center" >
                                Go to account to verify and get refferal reward:  
                            </p>
                        </div> 
                        : 
                        (isSubscribed ? <h3>Account</h3> : <h3>Buy Subscription</h3> )
                      }
                    <br/>
                     {!isSubscribed ? <Link to="/subscribe" className="btn btn-primary mr-2">Buy Subscription</Link> : null}
                      <Link to="/account" className="btn btn-info mr-2">Account</Link>
                </div>
          }
 {/*          <ApplePay>
            <Route exact path='/apple-pay'></Route>
          </ApplePay> */}
    <br/>
    {/* {isSubscribed ? <div style={{ color:'#257883' }}>
    Member Account
  </div>  : (auth.loggedIn==1 ? (localCredits>=1 ? <div style={{ color:'#257883' }}>
    Account Credits: {auth.credits } + non account credits:{localCredits}
  </div> :  <div style={{ color:'#257883' }}> Account Credits: {auth.credits } </div>  ) : <div style={{ color:'#b73133' }}>
    Credits: {localCredits}
  </div> ) }  */}
   {isSubscribed ? <div style={{ color:'#257883' }}>
    Member Account
  </div>  : <div style={{ color:'red' }}>
    non-Member Account
  </div> } 
      <br/>
      
      <h2>Welcome to our AI Writing Tools!</h2>
      <p>We know that writing cover letters, resumes, emails, and social media posts can be time-consuming and overwhelming, especially when you're applying for multiple jobs or trying to reach out to potential clients. That's where we come in.</p>
      
      <h3>Select an AI Writing Tool</h3>
      {width >= 768 ? <AIBuilderCards /> : <AIBuilderCardsMobile/> }
      

      <br/>
      <p>Our AI writing tools use advanced artificial intelligence technology to generate personalized documents for you. Simply enter the required information and our AI will generate a unique document for you, tailored to your needs.</p>
      <p>Not only do our AI writing tools save you time, they also help you stand out from the competition. Our AI is trained on a diverse range of documents, so it can generate a wide range of styles and tones to suit your needs.</p>
      {/* <p>To get started, choose one of the options:</p> */}
      <br/>
      <br/>
      
      <br/>
      <br/>

     

   
    </div>

   
  );
}

export default Home;
