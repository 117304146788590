import React,{useState,useEffect,useContext} from 'react';
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import PersonalInfo from './PersonalInfo';
import WorkExperience from './WorkExperience';
import Education from './Education';
import Skills from './Skills';
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import emailjs from 'emailjs-com';
import storeLocalCredits from '../functions/storeLocalCredits.jsx';
import { retrieveLocalCredits } from '../functions/retrieveLocalCredits';
import { incrementLocalCredits as increment } from '../functions/incrementLocalCredits.jsx';
import { decrementLocalCredits as decrement } from '../functions/decrementLocalCredits.jsx';
import { Container } from 'react-bootstrap';
import "./ResumePreview.css";
import { FaSpinner } from 'react-icons/fa';
import storeActivity from '../functions/storeActivity.jsx';
import {MyAlert,MyAlertMessage,MyAlertDocument} from '../components/MyAlert.jsx';


const { Configuration, OpenAIApi } = require("openai");
const ChatGPTAPIKey = process.env.REACT_APP_GPT_API_KEY;
//const ChatGPTAPIKey = "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";
const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY;
const emailjsAPIKEY= process.env.REACT_APP_EMAILJS_API_KEY;

const BrainGeniusAPIKey = process.env.REACT_APP_GENIUS_BRAIN_API_KEY;

const freeCritiques = 2;

const ResumePreview = ({
  personalInfo,
  workExperience,
  education,
  skills,
  pasted,
  pastedResume,
}) => {


  
  
  const { auth,setAuth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [resumeText, setResumeText] = useState('');
  const [localCredits, setLocalCredits] = useState(0);
  const [sessionId, setSessionId] = useState('');
  const [loggedIn, setLoggedIn] = useState('');
  const [email, setEmail] = useState('');
  const [credits, setCredits] = useState(0);
  const [aIResume, setAIResume] = useState('');
  const [shareResume, setShareResume] = useState(true); //set the state of checkbox

  const [jobPosition, setJobPosition] = useState('');
  const [desiredCompany, setDesiredCompany] = useState('');
  const [responsibilities, setResponsibilities] = useState('');
  const [generatedCritique, setGeneratedCritique] = useState('');
  const [usedFreeCritiqueCount, setUsedFreeCritiqueCount] = useState(0);
  const [triggerBuyButtonAlert, setTriggerBuyButtonAlert] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const [documentOpen, setDocumentOpen] = useState(false);
  const handleDocumentOpenModal = () => setDocumentOpen(true);
  const handleDocumentCloseModal = () => setDocumentOpen(false);

  const [username, setUsername] = useState('');
  

  const [isSubscribed, setIsSubscribed] = useState(false);

  const configuration = new Configuration({
    apiKey: ChatGPTAPIKey,
  });
  
  const openai = new OpenAIApi(configuration);



  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'ResumeExperiencePreview'             ,'Page'              ,'Landed on ResumeExperiencePreview page');
  }, []);


  useEffect(() => {         
    if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
      setIsSubscribed(true);
      }
  }, []);


  useEffect(() => { 
    async function fetchData() {
    setUsername(localStorage.getItem('username'));
    setCredits(localStorage.getItem('credits'));
    setLoggedIn(localStorage.getItem('loggedIn'));
    setSessionId(localStorage.getItem('sessionId'));
    setEmail(localStorage.getItem('email'));
    setEmailAddress(auth.email);
    await getFreeCritiquesUsedCount();
    }
    fetchData();
  }, []);

 
   const updateCredits = () => {     
    //console.log("updating credits");   
    axios.post('/get-credits', { sessionId: auth.sessionId }, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded',},
       withCredentials: true // This sends the cookie with the request
    })
      .then((response) => {
        // set the credit balance to the credits state
        setAuth({
          ...auth,
          credits: response.data.credits, // Update the credits field
        });
        //console.log(auth.credits);   
      })
      .catch(error => {
        //console.error(error);
      });
      localStorage.setItem('credits', auth.credits);
  };

  function stripSQLInjection(text) {
    text = String(text);
    return text.replace(/'/g, "&apos;")
    .replace(/"/g, "&quot;")
    .replace(/;/g, "&semi;")
    .replace(/-/g, "&dash;")
    .replace(/\+/g, "&plus;");
  }

  const decrementLocalCredits = async () => {
    const creditsToken = localStorage.getItem('localcreditstoken142023342');
  const result = decrement(creditsToken);
  if(result != 1){
  setLocalCredits(localCredits -1);
  }
  //window.location.replace("/");
  };

  const fetchData = async () => {
    const tempCredits = await retrieveLocalCredits();
    if(tempCredits == -1){
      //create a new token to store local cedits
      storeLocalCredits(-1,0);
      
    }else{
      setLocalCredits(tempCredits);
    };
  };

  const sendEmail = async (email, coverLetter) => {
    try {
      await emailjs.send(
        "service_j8ecen9",
        "template_1uenfe9",
        {
          to: email,
          subject: 'Generated Resume',
          message: coverLetter,
        },
        emailjsAPIKEY
      );
    } catch (error) {
      //console.error(error);
    }
  };

  const sendEmailServerSide = async (email, coverLetter,emailType,documentType) => {
    try {
      await axios.post('/send-email', {sessionId:auth.sessionId,email:email,message:coverLetter,emailType:emailType,documentType:documentType},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
    } catch (error) {
      //console.error(error);
    }
  };

  const sendEmailCritique = async (email, critique) => {
    try {
      await emailjs.send(
        "service_j8ecen9",
        "template_1uenfe9",
        {
          to: email,
          subject: 'Generated Resume Critique',
          message: critique,
        },
        emailjsAPIKEY
      );
    } catch (error) {
      //console.error(error);
    }
  };

  const sendResumeTextToServer = (resumeText) => {
    const endpoint = '/api/resume'; // Replace with the URL of your endpoint
    const data = { resumeText };
  
    axios.post(endpoint, data)
      .then((response) => {
        // The text for the resume was successfully saved to the server
        ////////////console.log(response.data);
      })
      .catch((error) => {
        //console.error(error);
      });
  };


  const getFreeCritiquesUsedCount = async () => {
    const endpoint = '/getUsedFreeCritiques'; // Replace with the URL of your endpoint
    const data = { sessionId: auth.sessionId };
    await axios.post(endpoint, data)
  .then((response) => {
   
    setUsedFreeCritiqueCount(response.data.count);
  })
  .catch((error) => {
    //console.error(error);

  });
 

  };


  const sendResumeFieldsToServer = (personalInfo, workExperience, education, skills,resumeText,input,service,prompt) => {
    const endpoint = '/store-resume'; // Replace with the URL of your endpoint

    const workExperienceObject = Object.assign({}, workExperience);
    
    let workExperienceText = 'Work Experience:\n';
    workExperience.forEach((experience) => {
      workExperienceText += `${experience.position}, ${experience.company}\n `;
      workExperienceText += `Dates: ${experience.startDate} - ${experience.endDate}\n `;
      workExperienceText += `Responsibilities: ${experience.responsibilities}\n `;
      workExperienceText += '\n';
    });

    let educationText = 'Education:\n';
    education.forEach((edu) => {
      educationText += `${edu.degree}, ${edu.fieldOfStudy}, ${edu.school}\n `;
      educationText += `Dates: ${edu.startDate} - ${edu.endDate}\n `;
      educationText += '\n';
    });
    ////////////console.log(educationText);
    let skillsText = 'Skills:\n ';
     skills.forEach((skill) => {
      skillsText += `${skill}`;
    }); 
    //let skillsText = `Skills:${skills}`;
    //////////console.log("generatedCritique: ");
    //////////console.log(generatedCritique);
    //////////console.log("input: ");
    //////////console.log(input);


    personalInfo = stripSQLInjection(personalInfo);
    workExperienceText = stripSQLInjection(workExperienceText);
    educationText = stripSQLInjection(educationText);
    skillsText = stripSQLInjection(skillsText);
    input = stripSQLInjection(input);
    input = input.replace(/[^\x00-\x7F]/g, ' ');
    prompt = stripSQLInjection(prompt);
    prompt = prompt.replace(/[^\x00-\x7F]/g, ' ');
    resumeText = stripSQLInjection(resumeText);
    resumeText = resumeText.replace(/[^\x00-\x7F]/g, ' ');
      
    const data = {sessionId: auth.sessionId,emailAddress:emailAddress, personalInfo:personalInfo, workExperienceText:workExperienceText, educationText:educationText, skillsText:skillsText,resumeText:resumeText, aIResume: input,shareResume:shareResume , service:service,prompt: prompt,unstrippedResumeText:input };
    
    ////////////console.log(data);
    axios.post(endpoint, data)
      .then((response) => {
        // The fields for the resume were successfully saved to the server
        ////////////console.log(response.data);
      })
      .catch((error) => {
        //console.error(error);
      });
  };

  const generateResumeText = () => {
    // Generate the text for the resume
    let text = `Name: ${personalInfo.name}\n`;
    text += `Email: ${personalInfo.email}\n`;
    text += `Location: ${personalInfo.location}\n`;
    text += `Phone: ${personalInfo.phone}\n`;
    text += '\n';

    text += 'Work Experience:\n';
    workExperience.forEach((experience) => {
      text += `${experience.position}, ${experience.company}\n`;
      text += `Dates: ${experience.startDate} - ${experience.endDate}\n`;
      text += `Responsibilities: ${experience.responsibilities}\n`;
      text += '\n';
    });

    text += 'Education:\n';
    education.forEach((edu) => {
      text += `${edu.degree}, ${edu.fieldOfStudy}, ${edu.school}\n`;
      text += `Dates: ${edu.startDate} - ${edu.endDate}\n`;
      text += '\n';
    });

    text += 'Skills:\n';
    skills.forEach((skill) => {
      text += `${skill}`;
    });

    return text;
  };

 

  useEffect(() => {
    // Generate the text for the resume and update the state
    ////////////console.log("workexperiencePREVIEW:");
    ////////////console.log(workExperience);

    ////////////console.log(pasted);
    ////////////console.log(pastedResume);
    if(pasted){
      setResumeText(pastedResume);
    }else{
    setResumeText(generateResumeText());
    }
  }, [personalInfo, workExperience, education, skills]);




  
  const critiqueResume = async () => {
    if( auth.loggedIn==1 ){//logged in
     if ((localCredits + auth.credits<1) && !isSubscribed ) {
       alert('You are not subscribed.  Please subscribe before generating a premium resume critique.');
      // setTriggerBuyButtonAlert(true);
      handleOpenModal();
      return;
     }
    }else{ // not logged in

      alert('Sign up and Login before taking this action');
      window.location.replace("/#/signupform");

   }
    setIsLoading(true);
    ////////////console.log("preview pasted?");
    ////////////console.log(pasted);
    ////////////console.log("preview Resume:");
    ////////////console.log(pastedResume);
    if(pasted){
      setResumeText(pastedResume);
    }else{
    setResumeText(generateResumeText());
    }
try{
 // let prompt1 = `For the following question think like an expert career advisor, be specific, and do not return my resume your response. I am applying for the role of ${jobPosition}, critique the following resume: ${resumeText} `;
  let prompt1 = `The 'Generate AI Optimized Resume' re-writes a resume optimizing it for a specific role. For the following question think like an expert career advisor, be specific, and do not return my resume your response. I am applying for the role of ${jobPosition}, critique the following resume and make sure to explain how the the 'Generate AI Optimized Resume' feature can improve it: '${resumeText}' `;

  prompt1 = prompt1.trim();

  //////////console.log("prompt1: ");
  //////////console.log(prompt1);
/*    const response = await openai.createCompletion({
       model: 'text-davinci-003',
       prompt: prompt1,
       temperature: 0.7,
       max_tokens: 1500,
       top_p: 1,
       frequency_penalty: 0,
       presence_penalty: 0,
       
     }); */
/*      const response = await openai.createChatCompletion({
      model: 'gpt-3.5-turbo',
      messages: [{"role": "user", "content": prompt1}]
      //temperature: 0.7,
      //max_tokens: 2000,
      //top_p: 1,
      //frequency_penalty: 0,
      //presence_penalty: 0,

   }); */
   const response = await axios.post(
    'https://geniusbrain.zapto.org:4433/generate',
    {
      text:  prompt1 },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${BrainGeniusAPIKey}` // Use Genius Brain API key
      }
    }
  );
    ////////////console.log(response);

    if(response.status == 429){
      alert("issue with server: too many request. Please try again later.");
     }

    if(localCredits>=1 && response.status == 200){
   //  const newCreditCount = auth.credits - 1;
     decrementLocalCredits()
    // setLocalCredits(newCreditCount);
    }else{
      if(response.status == 200){
     axios.post('/subtract-credit', {sessionId:auth.sessionId},{
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     })
       .then((response) => {
         ////////////console.log(response.status);
         // show success message or update the credit count in the UI
         setAuth({
          ...auth,
          credits: credits - 1, // Update the credits field
        });
        //console.log("auth.credits");
        localStorage.setItem('credits', auth.credits);

       })
       .catch((error) => {
         ////////////console.log(error);
         // show error message
       }); 
      }
      
   };

   try {
     //const prompt = `take this resume and rewrite  with severe improvements: "${resumeText}"`;
     setIsLoading(false);
     setGeneratedCritique(response.data.generated_text);
     // Make the POST request to the insert-cover-letter route
/*      axios.post('/insert-cover-letter', {  sessionId: auth.sessionId, letter_text:generatedCoverLetter }, {
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     })
       .then(response => {
         // The resume was inserted successfully
       })
       .catch(error => {
         //console.error(error);
       }); */

    // setAIResume(generatedCritique);
     sendResumeFieldsToServer(personalInfo, workExperience, education, skills, resumeText,response.data.generated_text,"Critique",prompt1);
     //sendEmailCritique(emailAddress, response.data.generated_text);
     sendEmailServerSide(emailAddress, response.data.generated_text,"document","Resume Critique");
     setDocumentOpen(true);
     updateCredits();
     setIsLoading(false);
   } catch (error) {
     //console.error(error);
     try{
      axios.post('/log-error', {  sessionId: auth.sessionId, page:"email generator",errorCode:error }, {
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     })
       .then(response => {
         // The resume was inserted successfully
       })
       .catch(error => {
         //console.error(error);
       });
     }catch{
      setIsLoading(false);
       //console.error("error logging error");
     }
     setIsLoading(false);
   }
  }catch(error){

    alert("Error processing: you were not charged.");
     setIsLoading(false);
     try{
     axios.post('/log-error', {  sessionId: auth.sessionId, page:"resume generator",errorCode:error }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true // This sends the cookie with the request
    })
      .then(response => {
        // The resume was inserted successfully
      })
      .catch(error => {
        //console.error(error);
      });
    }catch{
      //console.error("error logging error");
    }
  }
   setIsLoading(false);
 };









 const freeCritiqueResume = async () => {
  if( auth.loggedIn==1 ){//logged in

  }else{ // not logged in

    alert('Sign up and Login before taking this action');
    window.location.replace("/#/signupform");

 }
  setIsLoading(true);
  ////////////console.log("preview pasted?");
  ////////////console.log(pasted);
  ////////////console.log("preview Resume:");
  ////////////console.log(pastedResume);
  if(pasted){
    setResumeText(pastedResume);
  }else{
  setResumeText(generateResumeText());
  }
try{


//let prompt1 = `Think like an expert career advisor and be specific. I am applying for the role of ${jobPosition}, critique the following resume: ${resumeText} `;
//let prompt1 = `For the following question think like an expert career advisor, be specific, and do not return my resume your response. I am applying for the role of ${jobPosition}, critique the following resume: ${resumeText} `;
let prompt1 = `reword my resume experience optimized for the ${jobPosition} role I am applying for don't add experience I do not have : '${resumeText}'`;

//////////console.log("test: ");
prompt1 = prompt1.trim();
//////////console.log("prompt1: ");
//////////console.log(prompt1);
/*  const response = await openai.createCompletion({
     model: 'text-davinci-003',
     prompt: prompt1,
     temperature: 0.7,
     max_tokens: 1500,
     top_p: 1,
     frequency_penalty: 0,
     presence_penalty: 0,
     
   }); */
/*    const response = await openai.createChatCompletion({
    model: 'gpt-3.5-turbo',
    messages: [{"role": "user", "content": prompt1}]

 });
 */
 const response = await axios.post(
    'https://geniusbrain.zapto.org:4433/generate',
    {
      text:  prompt1 },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${BrainGeniusAPIKey}` // Use Genius Brain API key
      }
    }
  );
  ////////////console.log(response);

  if(response.status == 429){
    alert("issue with server: too many request. Please try again later.");
   };


    try{
      setIsLoading(false);
      setGeneratedCritique(response.data.generated_text);
      sendResumeFieldsToServer(personalInfo, workExperience, education, skills, resumeText,response.data.generated_text,"Critique",prompt1);
      setDocumentOpen(true);
      updateCredits();
      sendEmailServerSide(emailAddress, response.data.generated_text,"document","Resume Critique");
    }catch (error){

      try{
        axios.post('/log-error', {  sessionId: auth.sessionId, page:"Send resume to server",errorCode:error }, {
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         withCredentials: true // This sends the cookie with the request
       })
         .then(response => {
           // The resume was inserted successfully
         })
         .catch(error => {
           //console.error(error);
         });
       }catch{
        setIsLoading(false);
         //console.error("error logging error");
       }
       setIsLoading(false);
    }
 

 try {


  // sendEmailCritique(emailAddress, response.data.generated_text);
   setIsLoading(false);
 } catch (error) {
   //console.error(error);
   try{
    axios.post('/log-error', {  sessionId: auth.sessionId, page:"email generator",errorCode:error }, {
     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
     withCredentials: true // This sends the cookie with the request
   })
     .then(response => {
       // The resume was inserted successfully
     })
     .catch(error => {
       //console.error(error);
     });
   }catch{
    setIsLoading(false);
     //console.error("error logging error");
   }
   setIsLoading(false);
 }
}catch(error){

  alert("Error processing: you were not charged.");
   setIsLoading(false);
   try{
   axios.post('/log-error', {  sessionId: auth.sessionId, page:"resume generator",errorCode:error }, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    withCredentials: true // This sends the cookie with the request
  })
    .then(response => {
      // The resume was inserted successfully
    })
    .catch(error => {
      //console.error(error);
    });
  }catch{
    //console.error("error logging error");
  }
}
 setIsLoading(false);
};











 const generateResume = async () => {
    if( auth.loggedIn==1 ){//logged in
     if ((localCredits + auth.credits<1) && !isSubscribed ) {
       alert('You are not subscribed.  Please subscribe before generating a premium resume.');
       setTriggerBuyButtonAlert(true);
       handleOpenModal();
       return;
     }
    }else{ // not logged in

      alert('Sign up and Login before taking this action');
      window.location.replace("/#/signupform");
    /* if (localCredits<1) {
      alert('You have no credits. Please make a payment before generating a resume.');
      return;
    } */
   }
    setIsLoading(true);

    if(pasted){
      setResumeText(pastedResume);
    }else{
    setResumeText(generateResumeText());
    }
try{
   // const prompt = `Write a standout tailored resume for a ${jobPosition} at ${desiredCompany}, and only if they match my resume highlight these responsibilities "${responsibilities}". Here is my current resume: ${resumeText} `
   //const prompt = `Job position I am applying for: "${jobPosition}", Company I am applying to: "${desiredCompany}", What the company is looking for in a canidate: "${responsibilities}", Here is my current resume: "${resumeText}" `
//   const prompt = `Write me an improved resume. Job position I am applying for: "${jobPosition}", Company I am applying to: "${desiredCompany}",  Here is my current resume: "${resumeText}" `
let prompt = '';
if(generatedCritique != '' && generatedCritique.length < 400 ){

   prompt = `reword my resume experience optimized for the ${jobPosition} role I am applying for don't add experience I do not have : '${resumeText}'`;

} else 
{
   //prompt = `Write me an improved resume. Job position I am applying for: "${jobPosition}",  Here is my current resume: "${resumeText}" `;
   prompt = `reword my resume experience optimized for the ${jobPosition} role I am applying for don't add experience I do not have : '${resumeText}'`;

}


prompt = prompt.trim();

//////////console.log(prompt);
/*  const response = await openai.createCompletion({
     model: 'text-davinci-003',
     prompt: prompt,
     temperature: 0.7,
     max_tokens: 2400,
     top_p: 1,
     frequency_penalty: 0,
     presence_penalty: 0,
     
   }); */
/*    const response = await openai.createChatCompletion({
    model: 'gpt-3.5-turbo',
    messages: [{"role": "user", "content": prompt}]
    //temperature: 0.7,
    //max_tokens: 2000,
    //top_p: 1,
    //frequency_penalty: 0,
    //presence_penalty: 0,

 }); */

 const response = await axios.post(
    'https://geniusbrain.zapto.org:4433/generate',
    {
      text:  prompt },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${BrainGeniusAPIKey}` // Use Genius Brain API key
      }
    }
  );
    ////////////console.log(response);

    if(response.status == 429){
      alert("issue with server: too many request. Please try again later.");
     }

    if(localCredits>=1 && response.status == 200){
   //  const newCreditCount = auth.credits - 1;
     decrementLocalCredits()
    // setLocalCredits(newCreditCount);
    }else{
     
      if(response.status == 200){

     axios.post('/subtract-credit', {sessionId:auth.sessionId},{
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     }).then((response) => {
         ////////////console.log(response.status);
         // show success message or update the credit count in the UI
   
       
             setAuth({
               ...auth,
               credits: credits - 1, // Update the credits field
             });
             //console.log("auth.credits");
             localStorage.setItem('credits', auth.credits);
        
       }).catch((error) => {
         ////////////console.log(error);
         // show error message
       }); 
      }
      
   };

   try {
     //const prompt = `take this resume and rewrite  with severe improvements: "${resumeText}"`;
     setIsLoading(false);
     const generatedCoverLetter = response.data.generated_text;
     // Make the POST request to the insert-cover-letter route
/*      axios.post('/insert-cover-letter', {  sessionId: auth.sessionId, letter_text:generatedCoverLetter }, {
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     })
       .then(response => {
         // The resume was inserted successfully
       })
       .catch(error => {
         //console.error(error);
       }); */

    setAIResume(generatedCoverLetter);
    setDocumentOpen(true);
     sendResumeFieldsToServer(personalInfo, workExperience, education, skills, resumeText,response.data.generated_text,"Experience Section",prompt)
    // sendEmail(emailAddress, generatedCoverLetter);
    sendEmailServerSide(emailAddress, response.data.generated_text,"document","Experience Section");
    updateCredits();
     setIsLoading(false);
   } catch (error) {
     //console.error(error);
     try{
      axios.post('/log-error', {  sessionId: auth.sessionId, page:"email generator",errorCode:error }, {
       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
       withCredentials: true // This sends the cookie with the request
     })
       .then(response => {
         // The resume was inserted successfully
       })
       .catch(error => {
         //console.error(error);
       });
     }catch{
      setIsLoading(false);
       //console.error("error logging error");
     }
     setIsLoading(false);
   }
  }catch(error){

    alert("Error processing: you were not charged.");
     setIsLoading(false);
     try{
     axios.post('/log-error', {  sessionId: auth.sessionId, page:"resume generator",errorCode:error }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true // This sends the cookie with the request
    })
      .then(response => {
        // The resume was inserted successfully
      })
      .catch(error => {
        //console.error(error);
      });
    }catch{
      //console.error("error logging error");
    }
  }
   setIsLoading(false);
 };


 
 const generateResumeSubscribed  = async () => {
  if( auth.loggedIn==1 ){//logged in
   if ((localCredits + auth.credits<1) && !isSubscribed ) {
     alert('You are not subscribed.  Please subscribe before generating a premium resume.');
     setTriggerBuyButtonAlert(true);
     handleOpenModal();
     return;
   }
  }else{ // not logged in

    alert('Sign up and Login before taking this action');
    window.location.replace("/#/signupform");
  /* if (localCredits<1) {
    alert('You have no credits. Please make a payment before generating a resume.');
    return;
  } */
 }
  setIsLoading(true);

  if(pasted){
    setResumeText(pastedResume);
  }else{
  setResumeText(generateResumeText());
  }
try{
 // const prompt = `Write a standout tailored resume for a ${jobPosition} at ${desiredCompany}, and only if they match my resume highlight these responsibilities "${responsibilities}". Here is my current resume: ${resumeText} `
 //const prompt = `Job position I am applying for: "${jobPosition}", Company I am applying to: "${desiredCompany}", What the company is looking for in a canidate: "${responsibilities}", Here is my current resume: "${resumeText}" `
//   const prompt = `Write me an improved resume. Job position I am applying for: "${jobPosition}", Company I am applying to: "${desiredCompany}",  Here is my current resume: "${resumeText}" `
let prompt = '';
if(generatedCritique != '' && generatedCritique.length < 400 ){

 prompt = `reword my resume experience optimized for the ${jobPosition} role I am applying for don't add experience I do not have : '${resumeText}'`;

} else 
{
 //prompt = `Write me an improved resume. Job position I am applying for: "${jobPosition}",  Here is my current resume: "${resumeText}" `;
 prompt = `reword my resume experience optimized for the ${jobPosition} role I am applying for don't add experience I do not have : '${resumeText}'`;

}


prompt = prompt.trim();

//////////console.log(prompt);
/*  const response = await openai.createCompletion({
   model: 'text-davinci-003',
   prompt: prompt,
   temperature: 0.7,
   max_tokens: 2400,
   top_p: 1,
   frequency_penalty: 0,
   presence_penalty: 0,
   
 }); */
/*  const response = await openai.createChatCompletion({
  model: 'gpt-3.5-turbo',
  messages: [{"role": "user", "content": prompt}]
  //temperature: 0.7,
  //max_tokens: 2000,
  //top_p: 1,
  //frequency_penalty: 0,
  //presence_penalty: 0,

}); */
const response = await axios.post(
    'https://geniusbrain.zapto.org:4433/generate',
    {
      text:  prompt },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${BrainGeniusAPIKey}` // Use Genius Brain API key
      }
    }
  );
  ////////////console.log(response);

  if(response.status == 429){
    alert("issue with server: too many request. Please try again later.");
   }

/*   if(localCredits>=1 && response.status == 200){
 //  const newCreditCount = auth.credits - 1;
   decrementLocalCredits()
  // setLocalCredits(newCreditCount);
  }else{
   
    if(response.status == 200){

   axios.post('/subtract-credit', {sessionId:auth.sessionId},{
     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
     withCredentials: true // This sends the cookie with the request
   }).then((response) => {
       ////////////console.log(response.status);
       // show success message or update the credit count in the UI
 
     
           setAuth({
             ...auth,
             credits: credits - 1, // Update the credits field
           });
           //console.log("auth.credits");
           localStorage.setItem('credits', auth.credits);
      
     }).catch((error) => {
       ////////////console.log(error);
       // show error message
     }); 
    }
    
 }; */

 try {
   //const prompt = `take this resume and rewrite  with severe improvements: "${resumeText}"`;
   setIsLoading(false);
   const generatedCoverLetter = response.data.generated_text;
   // Make the POST request to the insert-cover-letter route
/*      axios.post('/insert-cover-letter', {  sessionId: auth.sessionId, letter_text:generatedCoverLetter }, {
     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
     withCredentials: true // This sends the cookie with the request
   })
     .then(response => {
       // The resume was inserted successfully
     })
     .catch(error => {
       //console.error(error);
     }); */

  setAIResume(generatedCoverLetter);
  setDocumentOpen(true);
   sendResumeFieldsToServer(personalInfo, workExperience, education, skills, resumeText,response.data.generated_text,"Experience Section",prompt)
  // sendEmail(emailAddress, generatedCoverLetter);
  sendEmailServerSide(emailAddress, response.data.generated_text,"document","Experience Section");
  updateCredits();
   setIsLoading(false);
 } catch (error) {
   //console.error(error);
   try{
    axios.post('/log-error', {  sessionId: auth.sessionId, page:"email generator",errorCode:error }, {
     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
     withCredentials: true // This sends the cookie with the request
   })
     .then(response => {
       // The resume was inserted successfully
     })
     .catch(error => {
       //console.error(error);
     });
   }catch{
    setIsLoading(false);
     //console.error("error logging error");
   }
   setIsLoading(false);
 }
}catch(error){

  alert("Error processing: you were not charged.");
   setIsLoading(false);
   try{
   axios.post('/log-error', {  sessionId: auth.sessionId, page:"resume generator",errorCode:error }, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    withCredentials: true // This sends the cookie with the request
  })
    .then(response => {
      // The resume was inserted successfully
    })
    .catch(error => {
      //console.error(error);
    });
  }catch{
    //console.error("error logging error");
  }
}
 setIsLoading(false);
};


  return (
    <div>
    <div className="mx-auto">
      <h3 className="text-center">Generate Optimized Section</h3>
      <br />
      <h3 className="mx-auto text-center">Job Position</h3>
      <p className="mx-auto text-center">(optional) (Enter desired job position:)</p>
      <div className="d-flex justify-content-center">
      <input className="mx-auto text-center"
        value={jobPosition}
        style={{ height: '25', width: '200px', maxWidth: '100%' }}
        onChange={(e) => setJobPosition(e.target.value)}
      />
      </div>
{/*       <br />
      <br />
      <h3 className="mx-auto text-center">Desired Company</h3>
      <p className="mx-auto text-center">(optional) (Enter the company you are applying to:)</p>
      <p className="mx-auto text-center">(Only fill this out if you want your resume tailored to specific company:)</p>
      <div className="d-flex justify-content-center">
      <input className="mx-auto text-center"
        value={desiredCompany}
        style={{ height: '25', width: '200px', maxWidth: '100%' }}
        onChange={(e) => setDesiredCompany(e.target.value)}
      />
      </div> */}
     {/*  <br />
      <br />
      <h3 className="mx-auto text-center">Specific position responsibilities</h3>
      <p className="mx-auto text-center">(optional) (Enter any responsibilities specific to the role:)</p>
      <p className="mx-auto text-center">(Only fill this out if you want your resume tailored to specific company and position:)</p>
      <div className="d-flex justify-content-center">
      <input className="mx-auto text-center"
        value={responsibilities}
        style={{ height: '25', width: '200px', maxWidth: '100%' }}
        onChange={(e) => setResponsibilities(e.target.value)}
      />
      </div> */}
      <br />
      <br />
      <h3 className="mx-auto text-center">Email Address</h3>
      <p className="mx-auto text-center">(Enter your email address:)</p>
      <div className="d-flex justify-content-center">
      <input value={ emailAddress} style={{ hight:'25',width: '200px' , maxWidth: '100%' }} onChange={(e) => setEmailAddress(e.target.value)} /> 
      </div>
      <br/>
      {/* {auth.loggedIn==1 ?(localCredits>=1 ? <div style={{ color:'#257883' }}>
          Account Credits: {auth.credits } + non account credits:{localCredits}
          </div> :  <div style={{ color:'#257883' }}> Account Credits: {auth.credits } </div> ) : <div style={{ color:'#b73133' }}>
          Credits: {localCredits}
      </div>} */}
    
    <div className="d-flex justify-content-center">

        <form>
            {/* <p className="mx-auto text-center">Opt in to our AI career network below</p> */}
            <input type="checkbox" checked={shareResume}  value={shareResume} style={{margin:"10px"}} onChange={() => setShareResume(!shareResume)} />       
            <label>Connected me to recruiters and companies.</label>
        
            {/* <button type="submit" onClick={handleSubmit}>Submit</button> */}
        </form>
    </div>

    <br/>

    {/* {(generatedCritique == '') ? <p className="mx-auto text-center">Free daily critiques used: {usedFreeCritiqueCount} / {freeCritiques} </p> : null} */}
    {auth.isVerified != 1 ? <p className="mx-auto text-center"><span style={{ color:'red' }}>Verify Email to access free Resume Critique</span></p> : null}
      <div className="d-flex justify-content-center">
{/*       {(usedFreeCritiqueCount < freeCritiques) && (auth.isVerified == 1) ? ((generatedCritique == '' && aIResume == '' ) ? (isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-success" onClick={freeCritiqueResume}>Critique my Resume ( Free ) </button> )) : null) : 
      ((generatedCritique == '' && aIResume == '' ) ? (isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-info" onClick={critiqueResume}>Critique My Resume ( Member Only ) </button> )) : null)} */}
      
      </div>
      <br/>
      {(generatedCritique != '') && (aIResume != '') ? <p className="mx-auto text-center"><span style={{ color:'#257883' }}>Now press Generate AI Optimized Resume to Automatically generate improved resume according to the critique.</span></p> : null}
      <div className="d-flex justify-content-center">
      { isSubscribed ? (( aIResume == '' ) ?(isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-primary" onClick={generateResumeSubscribed }>Generate AI Optimized Section </button> )): null):(( aIResume == '' ) ?(isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-primary" onClick={generateResume}>Generate AI Optimized Section ( Member Only ) </button> )): null)}
      </div>
      <div className="d-flex justify-content-center">
              {auth.loggedIn==1 ?(localCredits>=1 ? <div style={{ color:'#257883' }}>
                  Account Credits: {auth.credits } + non account credits:{localCredits}
                </div> :  <div style={{ color:'#257883' }}> Account Credits: {auth.credits } </div> ) : <div style={{ color:'#b73133' }}>
                  Credits: {localCredits}
              </div>}
              {/* <MyAlert trigger={triggerBuyButtonAlert}/> */}
              
    </div>
      {generatedCritique && <div style={{ 
  justifyContent: 'center',
  backgroundColor: '#fafafa', 
  fontStyle: 'italic',
  whiteSpace: 'pre-wrap',
  textIndent: '50px' 
}}>
  <div className="d-flex justify-content-center">
  <p className="mx-auto text-center"><span style={{ fontWeight: 'bold', textDecoration: 'underline',color: '#004e84' }}>Resume Critique:</span></p>
<br/>
</div>
  {generatedCritique}
</div>}
      {aIResume && <div style={{ 
  justifyContent: 'center',
  backgroundColor: '#fafafa', 
  fontStyle: 'italic',
  whiteSpace: 'pre-wrap',
  textIndent: '50px' 
}}>
    <div className="d-flex justify-content-center">
  <p className="mx-auto text-center"><span style={{ fontWeight: 'bold', textDecoration: 'underline',color: '#004e84' }}> New Section:</span></p>
<br/>
</div>
  {aIResume}
</div>}
{/* {(generatedCritique != '')  ? <MyAlertDocument documentOpen={documentOpen}  setDocumentOpen={setDocumentOpen} handleDocumentClose={handleDocumentCloseModal} documentFile={generatedCritique}/> : null} */}
{(aIResume != '')  ? <MyAlertDocument documentOpen={documentOpen}  setDocumentOpen={setDocumentOpen} handleDocumentClose={handleDocumentCloseModal} documentFile={aIResume}/> : null}
{/* <MyAlertDocument documentOpen={true}  setDocumentOpen={setDocumentOpen} handleDocumentClose={handleDocumentCloseModal} documentFile={"AKJSKLJDLKSJDLKASJDLKASJDLKASJDLKJASLKDJASLKDJASLKJDLAKSJDLKASJDLKJDLKJSDLKAJDLKASJ"}/> */}
{open ? <MyAlertMessage open={open}  setOpen={setOpen} handleClose={handleCloseModal}/> : null}
 
  {/*      <PersonalInfo personalInfo={personalInfo} />
      <WorkExperience workExperience={workExperience} />
      <Education education={education} />
      <Skills skills={skills} /> */} 
    </div>
    </div>
  );
};

export default ResumePreview;
