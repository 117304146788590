import React, { useEffect, useContext,useState } from 'react';
import { Link } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
//import './ResumeHelp.css';
import { Container } from 'react-bootstrap';


export const CoverLetterHelp = () => {
const { auth } = useContext(AuthContext);
const [loaded, setLoaded] = useState(false);
useEffect(() => {
storeActivity(auth.sessionId, 'Page Landing', 'Cover Letter Help', 'Page', 'Landed on Cover Letter Help page');
}, []);

useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://www.tiktok.com/embed.js';
  script.async = true;
  script.onload = () => {
    setLoaded(true);
  };
  document.body.appendChild(script);
}, []);

return (
<Container>
<div className="cover-letter-help-container text-center">
<h1 className="mb-4">Get the Best Cover Letter Help at CareerGenius.org</h1>
<p className="mb-5">
Need help writing a winning cover letter? CareerGenius.org offers expert cover letter writing services to help you make a great first impression with potential employers. Our team of experienced writers will craft a customized cover letter tailored to the specific job you're applying for, using AI technology to optimize it for your target job.
</p>
<div>
      <h1>Tips for Writing an Effective Cover Letter</h1>
      <p>A cover letter is a critical component of your job application, as it provides the first opportunity for you to make a positive impression on a potential employer. In order to write an effective cover letter, there are several key elements you should include:</p>
      <ul>
        <li>
          <strong>Introduction:</strong> Start with a brief introduction that explains who you are and why you are writing the letter. This could be a sentence or two about your background and experience, and why you are interested in the job you are applying for.
        </li>
        <li>
          <strong>Body:</strong> The body of the letter is where you can elaborate on your qualifications and experience, and explain why you are the best candidate for the job. You should focus on the skills and qualifications that are relevant to the position, and highlight any accomplishments that demonstrate your expertise. It's also a good idea to mention specific projects or experiences that are relevant to the job you are applying for.
        </li>
        <li>
          <strong>Closing:</strong> The closing of your cover letter is your final opportunity to make a positive impression on the employer. Here, you should reiterate your interest in the job, and express your gratitude for the opportunity to apply. You should also include your contact information, so the employer can easily get in touch with you.
        </li>
      </ul>
      <p>When writing your cover letter, be sure to keep it concise and to the point, and avoid using overly formal language. Your cover letter should be a brief, clear, and persuasive argument for why you are the best candidate for the job, and why the employer should be interested in hearing more from you.</p>
    </div>
    <Link to="/coverletterBuilder" className="btn btn-primary">
      Cover Letter AI
    </Link>
    <br/>
<h2 className="mb-4">Why Choose CareerGenius.org for Your Cover Letter Help?</h2>
<ul className="mb-5">
<li>AI-powered technology for optimized cover letters</li>
<li>Customized templates to fit your unique needs and job target</li>
<li>Affordable pricing options to meet your budget</li>
<li>Dedicated customer support for all of your questions and concerns</li>
</ul>
<h2 className="mb-4">Get the Best Cover Letter Help at CareerGenius.org Today!</h2>
<p className="mb-5">
Ready to make a great first impression with your cover letter? Fill out our quick and easy online form at CareerGenius.org and one of our expert cover letter writers will be in touch to help you create the best cover letter for your job search.
</p>
{ loaded && (
        <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@joeyscouch/video/7198663029544750382" data-video-id="7198195116748705066" style={{ maxWidth: '605px', minWidth: '325px' }}>
          <section>
            <a target="_blank" title="@joeyscouch" href="https://www.tiktok.com/@joeyscouch?refer=embed">@joeyscouch</a>
            <a title="greenscreen" target="_blank" href="https://www.tiktok.com/tag/greenscreen?refer=embed">#greenscreen</a> CareerGenius.org resume writing tips for 2023
            <a title="resumetips" target="_blank" href="https://www.tiktok.com/tag/resumetips?refer=embed">#resumetips</a>
            <a title="resumehelp" target="_blank" href="https://www.tiktok.com/tag/resumehelp?refer=embed">#resumehelp</a>
            <a title="coverletter" target="_blank" href="https://www.tiktok.com/tag/coverletter?refer=embed">#coverletter</a>
            <a title="coverlettertips" target="_blank" href="https://www.tiktok.com/tag/coverlettertips?refer=embed">#coverlettertips</a>
            <a title="resumecritique" target="_blank" href="https://www.tiktok.com/tag/resumecritique?refer=embed">#resumecritique</a>
            <a target="_blank" title="♬ original sound - Joey" href="https://www.tiktok.com/music/original-sound-7198195201284770606?refer=embed">♬ original sound - Joey</a>
          </section>
        </blockquote>
      )}
<Link to="/" className="btn btn-primary">
      Home
    </Link>
  </div>
</Container>
);
};

export default CoverLetterHelp;