import React, { useState } from 'react';


const ReviewForm = ({ handleNext, handleBack }) => {


  const handleChange = (event) => {
  
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleBack();
  };

  return (
    <div>
{/*       <button type="button" onClick={handleBack}>
        Back
      </button> */}
      <br/>
    </div>
  );
};

export default ReviewForm;
