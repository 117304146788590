import React, { useState,useContext,useEffect } from 'react';
import axios from '../api/axios';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from "../context/AuthProvider";
import { Container } from 'react-bootstrap';

const ReviewForm = ({pastedSocialPost,currentStep,setPastedSocialPost,setCurrentStep,setPasted, handleNext}) => {

  const { auth,setAuth } = useContext(AuthContext);

  const handleChange = (event) => {
  
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNext();
  };

  const buildSocialPost = (event) => {
    event.preventDefault();
    setCurrentStep(2);
  };

  const pastedSocialPostSubmit = (event) => {
    event.preventDefault();
    setPasted(true);
    setCurrentStep(6);
    //console.log(pastedSocialPost);
  };


  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'CopyOrBuildSocialPost'             ,'Page'              ,'Landed on CopyOrBuildSocialPost page');
  }, []);

  return (
    <Container>
    <div className="mx-auto">
      <h3 className="text-center">Paste the topic of your Social Media Post</h3>
      <p></p>
      <div className="d-flex justify-content-center">
      <textarea value={pastedSocialPost} className="text-center" style={{ height:'300px',width: '800px', maxHeight: '50%', maxWidth: '100%' }} onChange={(e) => setPastedSocialPost(e.target.value)} />
      </div>
      <br/>
      <br/>
      <div className="d-flex justify-content-center">
      <button type="button" className="btn btn-secondary"  onClick={pastedSocialPostSubmit}>
        Next
      </button> 
      </div>
      <br/>
      <br/>
      <div className="justify-content-center">
      <p className="text-center" >Welcome to the Social Post Genius generator!</p>

<p className="text-center">To create your post, please enter the following information:</p>

<ul>
  <li>Social Media Platform: This is the social media platform you will be posting to. You can leave this blank if you prefer.</li>
  <li>Intent: This is the purpose or goal of your post. Some examples might be 'to make sales', 'get leads', 'entertain', or 'educate'. You can leave this blank if you prefer.</li>
  <li>Tone: This is the tone or attitude you want your post to convey. Some examples might be 'professional', 'persuasive', 'happy', or 'motivational'. You can leave this blank if you prefer.</li>
  <li>Email Address: Please enter your email address so we can contact you if necessary.</li>
</ul>

<p className="text-center" >Once you have entered all of the information, press the 'Generate SocialPost' button to create your post. Thank you for using the SocialPost generator!</p>
</div>
     {/*       <button type="button" onClick={buildSocialPost}>
        Build New SocialPost
      </button>  */}

<br/>
    </div>
    </Container>
  );
};

export default ReviewForm;
