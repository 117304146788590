import React, { useState } from 'react';
import axios from './api/axios';

const ForgotUsername = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/forgot-username', { email })
            .then(response => setMessage('Username has been sent to your email.'))
            .catch(error => setMessage('Error: Could not send username.'));
    };

    return (
        <div className="container">
            <h2>Forgot Username</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="form-control"
                    />
                </div>
                <button type="submit" className="btn btn-primary">Send Username</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ForgotUsername;
