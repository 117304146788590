import axios from '../api/axios';
import React, { useState,useEffect,useContext } from 'react';
import AuthContext from "../context/AuthProvider";

export const decrementLocalCredits = async (token) => {

  const { auth,setAuth } = useContext(AuthContext);
try{
  useEffect(() => {         
    axios.post('/get-credits', { sessionId: auth.sessionId }, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded',},
       withCredentials: true // This sends the cookie with the request
    })
      .then(response => {
        // set the credit balance to the credits state
        setAuth({
          ...auth,
          credits: response.data.credits, // Update the credits field
        });
      })
      .catch(error => {
        //console.error(error);
      });
      localStorage.setItem('credits', auth.credits);
  }, []);
}catch(error){

}

  try {
    await axios.post('/decrement-local-credits', {
      token,
    });
    return 1;
  } catch (error) {
    //console.error(error);
    return -1;
  }
  
};

// Decrement the credits in the database
//decrementCredits(token);
export default decrementLocalCredits;
