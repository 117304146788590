import React, { useState,useContext,useEffect } from 'react';
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { auth,setAuth } = useContext(AuthContext);

  const CLIENT_ID=process.env.REACT_APP_GMAIL_CLIENT_ID;
  const CLIENT_SECRET=process.env.REACT_APP_GMAIL_CLIENT_SECRET;
  const REDIRECT_URI=process.env.REACT_APP_GMAIL_REDIRECT_URL;
  const REFRESH_TOKEN=process.env.REACT_APP_GMAIL_REFRESH_TOKEN;


  // Extract client ID, client secret, and redirect URI from the provided JSON object
  const {
    web: {
      client_id,
      client_secret,
      redirect_uris: [redirect_uri]
    }
  } = JSON.parse('{"web":{"client_id":"1068616660332-fqfvrvdei0mgdslg1b8oavenkct8nlig.apps.googleusercontent.com","project_id":"cover-letter-generator-373321","auth_uri":"https://accounts.google.com/o/oauth2/auth","token_uri":"https://oauth2.googleapis.com/token","auth_provider_x509_cert_url":"https://www.googleapis.com/oauth2/v1/certs","client_secret":"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","redirect_uris":["https://developers.google.com/oauthplayground"]}}');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate that all form fields are filled out
    if (!name || !email || !message) {
      setError('Please fill out all fields');
      return;
    }
    setError('');
    setSuccess('Sending email...');

    // Use the Gmail API to send the email
    const encodedMessage = btoa(`To: careergeniusapp@gmail.com\nSubject: Contact Form Submission\n\nName: ${name}\nEmail: ${email}\nMessage: ${message}`).replace(/\+/g, '-').replace(/\//g, '_');
    const request = new Request(`https://www.googleapis.com/gmail/v1/users/me/messages/send?key=${client_id}`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${process.env.REACT_APP_GMAIL_API_TOKEN}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        raw: encodedMessage,
      }),
    });
    fetch(request)
      .then((response) => {
        if (response.ok) {
          setSuccess('Email sent successfully');
          setName('');
          setEmail('');
          setMessage('');
        } else {
          setError('Error sending email');
          setSuccess('');
        }
      })
      .catch((error) => {
        setError('Error sending email');
        setSuccess('');
      }); 
  };


  useEffect(() => { 
    axios.post('/log-activity', {  sessionId: auth.sessionId,event_type:"Page Landing",current_page:'Contact',object_type:'Page',specific_object:'Landed on Contact page'}, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true // This sends the cookie with the request
    });
  }, []);
 
  return(
    <div style={{ textAlign: 'center' ,padding: '20px',}}>
      <h2>Contact</h2>
      <p>Thank you for visiting our website! If you have any technical issues or questions, please don't hesitate to reach out to our team using the form below or by emailing us at <a href="mailto:careergeniusapp@gmail.com">careergeniusapp@gmail.com</a>.</p>
{/*       <form style={{ width: '50%', margin: '0 auto', display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
        <label htmlFor="name" style={{ marginBottom: '10px' }}>Name:</label>
        <input type="text" id="name" style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '20px' }} value={name} onChange={(e) => setName(e.target.value)} />
        <label htmlFor="email" style={{ marginBottom: '10px' }}>Email:</label>
        <input type="email" id="email" style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '20px' }} value={email} onChange={(e) => setEmail(e.target.value)} />
        <label htmlFor="message" style={{ marginBottom: '10px' }}>Message:</label>
        <textarea id="message" style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '20px', height: '200px' }} value={message} onChange={(e) => setMessage(e.target.value)} />
        <button type="submit" style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#337ab7', color: '#fff', cursor: 'pointer' }}>Send</button>
      </form> */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </div>
  );
};

export default Contact;

