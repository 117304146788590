import React, { useState,useContext,useEffect } from 'react';
import axios from '../api/axios';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from "../context/AuthProvider";
import { Container } from 'react-bootstrap';


const ReviewForm = ({pastedResume,currentStep,setPastedResume,setCurrentStep,setPasted, handleNext}) => {

    const tokenLimit = 1200;
    const charactersPerToken = 4;
    const characterLimit = tokenLimit * charactersPerToken;
 
    const [characters, setCharacters] = useState(0);
    const { auth,setAuth } = useContext(AuthContext);
    const handleChange = (event) => {
      const inputString = event.target.value;
      const inputWithoutSpaces = inputString.replace(/\s/g, "").length;
      const tokenCount = inputWithoutSpaces;
      setCharacters(tokenCount);
    };
    


  const handleSubmit = (event) => {
    event.preventDefault();
    handleNext();
  };

  const buildResume = (event) => {
    event.preventDefault();
    setCurrentStep(2);
  };

  const pastedResumeSubmit = (event) => {
    event.preventDefault();
    setPasted(true);
    setCurrentStep(6);
    //console.log(pastedResume);
  };

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'CopyOrBuildResumeExperience'             ,'Page'              ,'Landed on CopyOrBuildResumeExperience page');
  }, []);

  return (
    <div>
      <div className="mx-auto">
        <h3 className="text-center">Paste your Work Experience Section</h3>
        <p className="text-center">(Copy and paste one of your work experience sections here:)</p>
        <p className="text-center">(We will reword and optimize for the specific job you are applying to.)</p>
        {characters > characterLimit ? (
        <p style={{ color: "red" }} className="mx-auto text-center">Character limit exceeded! {characters} / {characterLimit}</p>
      ) : (
        <p className="mx-auto text-center">Non-empty characters remaining: {characterLimit - characters}</p>
      )}
        <div className="d-flex justify-content-center">
       <textarea  value={pastedResume} style={{ 
    height:'300px',
    width: '800px', 
    maxHeight: '50%', 
    maxWidth: '100%', 
    border: characters > characterLimit ? '4px solid red' : '1px solid gray',
    color: characters > characterLimit ? 'red' : 'black', 
  }}
  placeholder={
  `Employer – City, State                       Dates of Employment
  Job Title   
     Responsibilities and achievements, 
     in general terms 
     but without specific details 
     or company names.`}  // add placeholder text
  onFocus={(e) => { e.target.placeholder = ''; e.target.style.opacity = '1'; }}  // remove placeholder text and change opacity on focus
  onBlur={(e) => { e.target.placeholder = 
    `Employer – City, State                       Dates of Employment
      Job Title   
        Responsibilities and achievements, 
        in general terms 
        but without specific details 
        or company names.`;
     e.target.style.opacity = pastedResume ? '1' : '0.5'; }}  // restore placeholder text and opacity on blur
  
   onChange={(e) => {
    setPastedResume(e.target.value);
    handleChange(e);
  }}  />

      </div><br/>
      
        <div className="d-flex justify-content-center">
         <button disabled={characters > characterLimit} type="button" className="text-center btn btn-secondary" onClick={pastedResumeSubmit}>
          Continue
        </button>
        </div>
       <br/>
      </div>
    </div>
  );
};

export default ReviewForm;