import React, { useState,useContext,useEffect } from 'react';
import axios from './api/axios';
import Cookies from 'js-cookie';
import { UserContext } from './context/UserContext';
import AuthContext from "./context/AuthProvider";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import storeActivity from './functions/storeActivity.jsx';

const SignupForm = () => {

    const { auth,setAuth } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [credits, setCredits] = useState(0);
    const [loggedIn, setLoggedIn] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [email, setEmail] = useState('');
    const [user_role_type, setUser_role_type] = useState('');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    useEffect(() => {         
      //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
      storeActivity( auth.sessionId       ,"Page Landing"     ,'SignupForm'             ,'Page'              ,'Landed on SignupForm page');
    }, []);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    refferalCode: '',
  });

  const handleChange = (event) => {
    // Get the current input value
    const currentValue = event.target.value;

    // Use a regular expression to check for unwanted characters
    const unwantedCharacters = /[;'\"\s-]/g;
    if(unwantedCharacters.test(currentValue)) {
        // Stop the input
        event.preventDefault();
        alert("Characters ';' '\"' '\'' '-' are not allowed.");
    } else {
        const { name, value } = event.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    }
};
  

  const handleSubmit = (event) => {
    setIsButtonDisabled(true); // disable the button
    event.preventDefault();
    if (formData.password !== formData.confirmPassword) {
        // Show error message: "Passwords do not match"
        alert("Passwords do not match.");
        setIsButtonDisabled(false); // enable the button
        return;
      }
    
    // Test the input against the email regex
    const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
    if (formData.email == null || formData.email == ''|| !emailRegex.test(formData.email)) {
        // Show error message: "Passwords do not match"
        alert("Passwords do not match.");
        setIsButtonDisabled(false); // enable the button
        return;
      }
    axios.post('/signup', formData, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded',},
        withCredentials: true // This sends the cookie with the request
    })
      .then((response) => {

        if(response.status == 201){
            alert("Success! User created. Verification code sent to email.")    
                    
         };
        //console.log(response.status);
        axios.post('/login', formData, 
        {
          headers: {'Content-Type': 'application/x-www-form-urlencoded',},
          withCredentials: true // This sends the cookie with the request
        })
          .then((response) => {
            //console.log(response.status);
            // Redirect to homepage or show success message
            if(response.status ==200){
            const { user } = response.data;  
            sessionStorage.setItem('user', JSON.stringify(user));
            setUsername(response.data.username);
            setCredits(response.data.credits);
            setLoggedIn("1");
            localStorage.setItem('username', response.data.username);
            localStorage.setItem('credits', response.data.credits);
            localStorage.setItem('loggedIn', 1);
            localStorage.setItem('sessionId', response.data.sessionId);
            localStorage.setItem('email', response.data.email);
            localStorage.setItem('user_role_type', response.data.user_role_type);
            localStorage.setItem('isVerified', 0);
            setAuth({ username:response.data.username, credits:response.data.credits, loggedIn:1,sessionId:response.data.sessionId 
              ,email:response.data.email,user_role_type:response.data.user_role_type, isVerified:response.data.isVerified });//setGlobalUser([response.data.username,response.data.credits,true]) 
      
            window.location.replace("/#/account");
            }
          })
          .catch((error) => {
            //console.log(error);
            if(response.status == 400){
                alert("Invalid Referal Code")               
             };
             if(error.response && error.response.status == 400){
                alert("Invalid Referal Code")               
             };
            // Show error message
          });
    
        setIsButtonDisabled(false); // enable the button
        // Redirect to login page or show success message
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
            alert("This user already exists. Please try a different username.");
          } if (error.response && error.response.status === 410) {
            alert("This email already exists. Please try a different email.");
          } else 
          {
            if(error.response && error.response.status == 400){
                alert("Invalid Referal Code")               
             };
            //console.log(error);
            // Show error message
          }
        // Show error message
        setIsButtonDisabled(false); // enable the button
      });
  };

  return (
    <div class="d-flex align-items-center h-100">
        
    <div class="container">
    <br/>
    <h1>Sign up</h1>
        <br/>   
    <form onSubmit={handleSubmit}>
      <div class="form-group row">
        <label htmlFor="username" class="col-sm-2 col-form-label">*Username:</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <label htmlFor="email" class="col-sm-2 col-form-label">*Email:</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <label htmlFor="password" class="col-sm-2 col-form-label">*Password:</label>
        <div class="col-sm-10">
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <label htmlFor="confirmPassword" class="col-sm-2 col-form-label">*Confirm Password:</label>
        <div class="col-sm-10">
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            class="form-control"
          />
        </div>
      </div>

      <div class="form-group row">
      <label htmlFor="referredFrom" class="col-sm-2 col-form-label">*How did you hear about us?:</label>
      <div class="col-sm-10">
        <select
          id="referredFrom"
          name="referredFrom"
          value={formData.referredFrom}
          onChange={handleChange}
          required
          class="form-control"
        >
          <option value="">Select</option>
          <option value="Facebook">Facebook</option>
          <option value="TikTok">TikTok</option>
          <option value="Instagram">Instagram</option>
          <option value="LinkedIn">LinkedIn</option>
          <option value="Reddit">Reddit</option>
          <option value="Google">Google</option>
          <option value="YouTube">YouTube</option>
          <option value="Other">Other</option>
        </select>
      </div>
    </div>


      <div class="form-group row">
        <label htmlFor="refferalCode" class="col-sm-2 col-form-label">Refferal Code (optional):</label>
        <div class="col-sm-10">
          <input
            type="text"
            id="refferalCode"
            name="refferalCode"
            value={formData.refferalCode}
            onChange={handleChange}
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          {!isButtonDisabled ? <button type="submit"  disabled={isButtonDisabled} class="btn btn-primary">Sign up</button> : <p>Loading...</p>}
        </div>
      </div>
    </form>
    <p>Already a member? <Link to="/loginform">Login</Link> </p>
   
  </div>
  </div>
  
  );
  
      };
      
      export default SignupForm;