import axios from '../api/axios';
import React, { useState,useEffect,useContext } from 'react';

export const incrementLocalCredits = async (token) => {
    try {
      await axios.post('/increment-local-credits', {
        token,
      });
      return 1;
    } catch (error) {
      //console.error(error);
      return -1;
    }
    
  };
  
  // Increment the credits in the database
  //incrementCredits(token);
  export default incrementLocalCredits;