import React, { useEffect, useContext,useState } from 'react';
import { Link } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
//import './BestPractices.css';
import { Container } from 'react-bootstrap';


export const EffectiveCoverLetterWriting = () => {
const { auth } = useContext(AuthContext);
const [loaded, setLoaded] = useState(false);
useEffect(() => {
storeActivity(auth.sessionId, 'Page Landing', 'Best Practices for Resume and Cover Letter Writing', 'Page', 'Landed on Best Practices page');
}, []);

useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    script.onload = () => {
      setLoaded(true);
    };
    document.body.appendChild(script);
  }, []);

return (
<Container>

<div className="best-practices-container text-center">
<h1 className="mb-4">Discover the Best Practices for Resume and Cover Letter Writing at CareerGenius.org</h1>
<p className="mb-5">
Are you looking for the best practices for crafting an outstanding resume and cover letter? Look no further! At CareerGenius.org, our team of professional resume writers are here to share their expertise and help you write the best resume and cover letter possible. 
</p>
<div>
      <h1>Tips for Writing an Effective Cover Letter</h1>
      <p>A cover letter is a critical component of your job application, as it provides the first opportunity for you to make a positive impression on a potential employer. In order to write an effective cover letter, there are several key elements you should include:</p>
      <ul>
        <li>
          <strong>Introduction:</strong> Start with a brief introduction that explains who you are and why you are writing the letter. This could be a sentence or two about your background and experience, and why you are interested in the job you are applying for.
        </li>
        <li>
          <strong>Body:</strong> The body of the letter is where you can elaborate on your qualifications and experience, and explain why you are the best candidate for the job. You should focus on the skills and qualifications that are relevant to the position, and highlight any accomplishments that demonstrate your expertise. It's also a good idea to mention specific projects or experiences that are relevant to the job you are applying for.
        </li>
        <li>
          <strong>Closing:</strong> The closing of your cover letter is your final opportunity to make a positive impression on the employer. Here, you should reiterate your interest in the job, and express your gratitude for the opportunity to apply. You should also include your contact information, so the employer can easily get in touch with you.
        </li>
      </ul>
      <p>When writing your cover letter, be sure to keep it concise and to the point, and avoid using overly formal language. Your cover letter should be a brief, clear, and persuasive argument for why you are the best candidate for the job, and why the employer should be interested in hearing more from you.</p>
    </div>
<h2 className="mb-4">What You'll Learn About at CareerGenius.org</h2>
<ul className="mb-5">
<li>How to optimize your resume for your target job</li>
<li>The most effective techniques for writing a winning cover letter</li>
<li>Insider tips from experienced professional resume writers</li>
<li>Guidance on presenting your best self to potential employers</li>
</ul>
<h2 className="mb-4">Get the Best Resume and Cover Letter Writing Tips at CareerGenius.org Today!</h2>
<p className="mb-5">
Ready to start writing your best resume and cover letter yet? Visit CareerGenius.org and take advantage of our expertise and resources. We're here to help you every step of the way!
</p>
{ loaded && (
        <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@joeyscouch/video/7198195116748705066" data-video-id="7198195116748705066" style={{ maxWidth: '605px', minWidth: '325px' }}>
          <section>
            <a target="_blank" title="@joeyscouch" href="https://www.tiktok.com/@joeyscouch?refer=embed">@joeyscouch</a>
            <a title="greenscreen" target="_blank" href="https://www.tiktok.com/tag/greenscreen?refer=embed">#greenscreen</a> CareerGenius.org resume writing tips for 2023
            <a title="resumetips" target="_blank" href="https://www.tiktok.com/tag/resumetips?refer=embed">#resumetips</a>
            <a title="resumehelp" target="_blank" href="https://www.tiktok.com/tag/resumehelp?refer=embed">#resumehelp</a>
            <a title="coverletter" target="_blank" href="https://www.tiktok.com/tag/coverletter?refer=embed">#coverletter</a>
            <a title="coverlettertips" target="_blank" href="https://www.tiktok.com/tag/coverlettertips?refer=embed">#coverlettertips</a>
            <a title="resumecritique" target="_blank" href="https://www.tiktok.com/tag/resumecritique?refer=embed">#resumecritique</a>
            <a target="_blank" title="♬ original sound - Joey" href="https://www.tiktok.com/music/original-sound-7198195201284770606?refer=embed">♬ original sound - Joey</a>
          </section>
        </blockquote>
      )}
<Link to="/" className="btn btn-primary">
Home
</Link>
</div>
</Container>
);
};
export default EffectiveCoverLetterWriting;