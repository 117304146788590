import React, { useState, useEffect } from 'react';
import ResumeForm from './ResumeForm.jsx';
import ResumePreview from './ResumePreview.jsx';
import WorkExperienceForm from './WorkExperienceForm.js';

const ResumeBuilder = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [workExperience, setWorkExperience] = useState([]);
  const [educationInfo, setEducation] = useState([]);
  const [skills, setSkills] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [pasted, setPasted] = useState(false);
  const [pastedResume, setPastedResume] = useState('');

  const addWorkExperience = (experience) => {
  setWorkExperience((workExperience) =>[...workExperience, ...experience]);
  };


  const addEducation = (education) => {
    setEducation([...educationInfo, ...education]);
  };

  const handleStepChange = (step) => {
    setCurrentStep(step)
  };


  const addSkills = (skills) => {
    setSkills([...skills, skills]);
  };

  const updatePersonalInfo = (info) => {
    setPersonalInfo({ ...personalInfo, ...info });
  };
  ////console.log("workexperience:");
  ////console.log(workExperience);
  return (
    <div>

      <ResumeForm
        personalInfo={personalInfo}
        updatePersonalInfo={updatePersonalInfo}
        addWorkExperience={addWorkExperience}
        addEducation={addEducation}
        addSkills={addSkills}
        currentStep={currentStep} 
        pasted={pasted}
        pastedResume={pastedResume}
        setCurrentStep={handleStepChange} 
        setPastedResume={setPastedResume}
        setPasted={setPasted}
      />
     {currentStep == 6 ?
       <ResumePreview
        personalInfo={personalInfo}
        workExperience={workExperience}
        education={educationInfo}
        skills={skills}
        pasted={pasted}
        pastedResume={pastedResume}
      /> : null} 
    </div>
  );
};

export default ResumeBuilder;
