import React, { useRef, useEffect,useState,useContext } from "react";
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import storeActivity from '../functions/storeActivity.jsx';
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";

// import "./BuyCredits.css";
const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY






 export const PayPalButtonTrialPeriod =  (planId) => {

  const [sessionId, setSessionId] = useState('');
  const { auth,setAuth } = useContext(AuthContext);
  const [subscriptionId,setSubscriptionId] = useState('');
  const [subscriptionData, setSubscriptionData] = useState(null);


  const paypalRef = useRef();


  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'Subscribe'             ,'Page'              ,'Landed on Subscribe page');
  }, []);

  useEffect(() => {
    console.log('planId updated:', planId);
  }, [planId]);

  

  const plans = {
    'P-5144885460270311GMPU5JVI': { plan:4, cost:9.99, subStatus:0, monthlyCredits:25, startDate:null,lastPaymentDate: null, billingInterval:'monthly'},
    //2:0.01,
    'P-1MG54108RM420493NMPU5JOA': { plan:5, cost:14.99, subStatus:0, monthlyCredits:50, startDate:null,lastPaymentDate: null, billingInterval:'monthly'},
    'P-8LN17015WT358443VMPU5HNY': { plan:6, cost:29.99, subStatus:0, monthlyCredits:100, startDate:null,lastPaymentDate: null, billingInterval:'monthly'},
    'P-5L601918AY002913AMQBWVDA': { plan:4, cost:119.99, subStatus:0, monthlyCredits:25, startDate:null,lastPaymentDate: null, billingInterval:'yearly'}
  };
  

  const updateSubscription = async (sessionId, subscriptionId, status, startDate, lastPaymentDate) => {
    try {
      const response = await axios.put('/update-subscription', {
        sessionId: auth.sessionId,
        //subscriptionId: subscriptionId,
        status: status,
     //   startDate: startDate,
      //  lastPaymentDate: lastPaymentDate
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);

    }
  };


  const removeAbandonedSubscriptions = async () => {
    try {
      const response = await axios.put('/remove-abandoned-subscriptions', {
        sessionId: auth.sessionId,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);

    }
  };
 

  const handleSubscribe = async ( plan, cost, subscriptionData , subStatus, monthlyCredits, startDate, lastPaymentDate, billingInterval ) => {
    console.log("inhandleSubscribe");
  
console.log(plan);
console.log(cost);
console.log(subscriptionId);
console.log(subStatus);
console.log(monthlyCredits);
console.log(startDate);
console.log(lastPaymentDate);
console.log(billingInterval);
    try {
      const response = await axios.post('/subscribe', {
        sessionId: auth.sessionId,
        plan: plan,
        cost: cost,
        subscriptionId: subscriptionData,
        status: subStatus,
        monthlyCredits: monthlyCredits,
        startDate: startDate,
        lastPaymentDate: startDate,
        billingInterval: billingInterval,
      });
      console.log("preresponsereadhandleSubscribe");
      console.log(response.data);
      console.log("postresponsereadhandleSubscribe");
    } catch (error) {
      console.error(error);
    }
  };


  
const createSubscription = async (planId) => {
  try {
    const response = await axios.post('/create-subscription', {
      planId: planId,
    });

    const subscriptionData = response.data.subscriptionData;
    const billingCycle = {
      frequency: {
        interval_unit: "MONTH",
        interval_count: 1,
      },
      tenure_type: "TRIAL",
      sequence: 1,
      total_cycles: 1,
      pricing_scheme: {
        fixed_price: {
          value: "0",
          currency_code: "USD",
        },
      },
    };
    subscriptionData.plan.billing_cycles.unshift(billingCycle);
    return subscriptionData;
  } catch (error) {
    console.error(error);
  }
};


return(
<div>
<script src="https://www.paypal.com/sdk/js?client-id=Aexzmc_t9EPOpQoL_ZVDBbLaaNJqp2rv2d14cDRTx_O2aHvMZ9yO_XvMUDUKLPk7tkS5YbukcvKRSbmQ&vault=true&disable-funding=credit&intent=subscription"></script>


 
<PayPalScriptProvider
      options={{ "client-id": "Aexzmc_t9EPOpQoL_ZVDBbLaaNJqp2rv2d14cDRTx_O2aHvMZ9yO_XvMUDUKLPk7tkS5YbukcvKRSbmQ&vault=true&disable-funding=credit" }}
    >
      <PayPalButtons 
        style={{
          layout: 'vertical',
          color: 'blue',
          shape: 'rect',
          label: 'subscribe',
          size: 'medium',
        }}
        createSubscription= {(data, actions) => {
          return actions.subscription
            .create({
              plan_id: 'P-5144885460270311GMPU5JVI',
             vault: true,
            })
            .then(async (subscriptionData) => {
              console.log(subscriptionData);
              const updatedSubscriptionData = await createSubscription('P-5144885460270311GMPU5JVI');
              setSubscriptionData(updatedSubscriptionData);
              handleSubscribe('P-5144885460270311GMPU5JVI', updatedSubscriptionData);
              return subscriptionData;
            });
        }}
        
/*         createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: planId.planId,
            vault: true,
          }).then(function (subscriptionData) {
            console.log("subscriptionData");
            console.log(subscriptionData); // Debugging statement
            setSubscriptionId(subscriptionData);
            handleSubscribe( plans[planId.planId].plan, plans[planId.planId].cost,subscriptionData, plans[planId.planId].subStatus, plans[planId.planId].monthlyCredits, plans[planId.planId].startDate, plans[planId.planId].lastPaymentDate, plans[planId.planId].billingInterval);
          console.log("pasthandleSubscribe");
            return subscriptionData;
            // Do something with the subscriptionId, such as passing it to the server
            //return subscriptionData.subscription_id;
          });
        }} */
        onApprove={async (data, actions) => {
          console.log("onApprove");
 /*          const details = await actions.order.capture();
          const name = details.payer.name.given_name;
          console.log("details");
          console.log(details); */
          console.log("data");
          console.log(data);
          console.log("actions");
          console.log(actions);
          console.log(planId.planId);
          console.log(plans[planId.planId]);
          console.log(plans[planId.planId].plan);
          updateSubscription(sessionId, 'subscriptionId', 1, 'startDate', 'lastPaymentDate');
          // handleSubscribe( plans[planId.planId].plan, plans[planId.planId].cost, plans[planId.planId].subStatus, plans[planId.planId].monthlyCredits, plans[planId.planId].startDate, plans[planId.planId].lastPaymentDate, plans[planId.planId].billingInterval);
          localStorage.setItem('user_role_type', 4);
          setAuth({
            ...auth,
            user_role_type: 4, // Update the credits field
          });
          console.log("pasthandleSubscribe");
        }}
        onCancel={async (data, actions) => {
          removeAbandonedSubscriptions()
          alert('Payment was cancelled');
        }}
      />
    </PayPalScriptProvider>
{/*     <button onClick={()=>         handleSubscribe( plans[planId.planId].plan, plans[planId.planId].cost,'', plans[planId.planId].subStatus, plans[planId.planId].monthlyCredits, plans[planId.planId].startDate, plans[planId.planId].lastPaymentDate, plans[planId.planId].billingInterval)}>click me</button>
    <button onClick={()=>                   updateSubscription(sessionId, 'subscriptionId', 1, 'startDate', 'lastPaymentDate')}>approval</button> */}


</div>
);
}; 

export default PayPalButtonTrialPeriod;


