import React, { useEffect, useContext,useState } from 'react';
import { Link } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
import { Container } from 'react-bootstrap';

export const ResumeCritique = () => {
  const { auth } = useContext(AuthContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    storeActivity(auth.sessionId, 'Page Landing', 'Resume ResumeCritque', 'Page', 'Landed on Resume ResumeCritque page');
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    script.onload = () => {
      setLoaded(true);
    };
    document.body.appendChild(script);
  }, []);

  return (
    <Container>
  <div className="resume-critique-container text-center">
    <h1 className="mb-4">Get the Best Resume Critique and Optimization Services at CareerGenius.org</h1>
    <p className="mb-5">
      Are you looking to take your job search to the next level with a top-notch resume? Look no further than CareerGenius.org, the premier provider of AI-powered resume critique and optimization services. Our team of experienced writers will evaluate your current resume and make custom suggestions for improvement, ensuring that your resume stands out from the competition and lands you your dream job. 
    </p>
    <div>
  <h3>Resume Critique: The Importance of Having Your Resume Reviewed by an Expert</h3>
  <p>
    I want to talk about an important topic that affects us all in our careers - resume critique. As many of you know, a resume is a critical document that represents you and your professional experiences to potential employers. It is your chance to showcase your achievements, skills, and qualifications, and set yourself apart from other candidates.
  </p>
  <p>
    But, as important as a resume is, it can also be a difficult and time-consuming task to create one that is effective and stands out. That's where a resume critique comes in. By having your resume reviewed by an expert, you can receive valuable feedback and make improvements that can help you get noticed by hiring managers.
  </p>
  <p>
    So, what are some of the key aspects of a strong resume that a critique should focus on? Let's take a look:
  </p>
  <ul>
    <li>Tailored to the job: Your resume should be specifically tailored to the job you're applying for. This means highlighting the skills and experiences that are relevant to the position, and making sure that your resume is optimized for the job description and requirements.</li>
    <li>Keyword optimization: Many companies use applicant tracking systems (ATS) to screen resumes. To ensure your resume makes it past the ATS, it is important to include keywords that match the job description. A resume critique can help you identify these keywords and ensure they are prominently featured in your resume.</li>
    <li>Achievements, not just responsibilities: Instead of just listing your responsibilities, focus on what you accomplished in each role. Use quantifiable data to demonstrate your impact and show how you made a difference in your previous positions.</li>
    <li>Concise and clear: Aim for a one-page resume and eliminate any irrelevant information. Use clear and concise language that is easy to understand, and avoid using complex language or industry jargon.</li>
    <li>Proofread: A resume critique should also include a thorough proofreading of your resume for typos, grammar errors, and formatting issues. These small mistakes can detract from the overall impact of your resume and give a poor impression to hiring managers.</li>
  </ul>
  <p>
    In conclusion, a resume critique can be a valuable tool in helping you create a strong and effective resume. By focusing on the key aspects of a good resume, you can increase your chances of getting noticed by hiring managers and landing your dream job. So, don't hesitate to seek out a professional resume critique and take the first step towards a successful career.
  </p>
</div>

    <h2 className="mb-4">Why Choose CareerGenius.org for Your Resume Critique and Optimization Needs?</h2>
    <ul className="mb-5">
      <li>Leading AI technology for the most comprehensive resume critique</li>
      <li>Customized recommendations and suggestions to fit your unique career needs</li>
      <li>Affordable pricing options to meet your budget and get the best value</li>
      <li>Dedicated customer support to answer any questions or concerns you may have</li>
    </ul>
    <h2 className="mb-4">Get Your Resume Critique and Optimization Services from CareerGenius.org Today!</h2>
    <p className="mb-5">
      Don't wait any longer to take the first step towards your dream job. Fill out our quick and easy online form at CareerGenius.org and let our expert writers help you elevate your resume to the next level.
    </p>
    <div>
      { loaded && (
        <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@joeyscouch/video/7198195116748705066" data-video-id="7198195116748705066" style={{ maxWidth: '605px', minWidth: '325px' }}>
          <section>
            <a target="_blank" title="@joeyscouch" href="https://www.tiktok.com/@joeyscouch?refer=embed">@joeyscouch</a>
            <a title="greenscreen" target="_blank" href="https://www.tiktok.com/tag/greenscreen?refer=embed">#greenscreen</a> CareerGenius.org resume writing tips for 2023
            <a title="resumetips" target="_blank" href="https://www.tiktok.com/tag/resumetips?refer=embed">#resumetips</a>
            <a title="resumehelp" target="_blank" href="https://www.tiktok.com/tag/resumehelp?refer=embed">#resumehelp</a>
            <a title="coverletter" target="_blank" href="https://www.tiktok.com/tag/coverletter?refer=embed">#coverletter</a>
            <a title="coverlettertips" target="_blank" href="https://www.tiktok.com/tag/coverlettertips?refer=embed">#coverlettertips</a>
            <a title="resumecritique" target="_blank" href="https://www.tiktok.com/tag/resumecritique?refer=embed">#resumecritique</a>
            <a target="_blank" title="♬ original sound - Joey" href="https://www.tiktok.com/music/original-sound-7198195201284770606?refer=embed">♬ original sound - Joey</a>
          </section>
        </blockquote>
      )}
    </div>
     

    
    <Link to="/" className="btn btn-primary">
      Home
    </Link>
  </div>
</Container>
  );
};

export default ResumeCritique;
