import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
import { Container } from 'react-bootstrap';

export const ResumeWritingServices = () => {
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    storeActivity(auth.sessionId, 'Page Landing', 'Resume Writing Services', 'Page', 'Landed on Resume Writing Services page');
  }, []);

  return (
    <Container>
      <div className="resume-writing-services-container text-center">
        <h1 className="mb-4">Get the Best Resume Writing Services at CareerGenius.org</h1>
        <p className="mb-5">
          Are you in search of professional resume writing services to help you stand out from the competition and land your dream job? Look no further! At CareerGenius.org, our team of experienced writers will craft a customized resume tailored to the specific job you're applying for, using AI technology to optimize it for your target job.
        </p>
        <div className="container">
        <h2>Why CareerGenius.org is the Best Resume Writing Service</h2>
        <p>A resume is one of the most important tools in your job search, and can play a critical role in determining whether or not you get an interview. With so much competition in the job market, it's essential to have a resume that stands out and showcases your achievements, skills, and qualifications in the best possible light.</p>
        <p>This is where CareerGenius.org comes in. As one of the leading resume writing services, CareerGenius.org leverages the latest technology and resources to help you create a resume that is optimized for maximum impact. Here are some of the key benefits of using CareerGenius.org's AI-powered resume writing service:</p>
        <ul>
          <li>
            <b>Customization:</b> CareerGenius.org's AI technology uses machine learning algorithms to analyze job descriptions and tailor your resume to the specific requirements of each job you apply for. This means that you don't have to manually customize your resume for each job application, saving you time and ensuring that your resume is always optimized for the position you're applying for.
          </li>
          <li>
            <b>Keyword optimization:</b> Many companies use applicant tracking systems (ATS) to screen resumes, and it's important to include relevant keywords in your resume to ensure it makes it past the ATS. CareerGenius.org's AI technology can analyze job descriptions and identify the most important keywords, ensuring that your resume is optimized for ATS and increasing your chances of landing an interview.
          </li>
          <li>
            <b>Data-driven insights:</b> CareerGenius.org's AI technology provides data-driven insights into your resume, including suggestions for improvement, so you can make informed decisions about how to optimize your resume for maximum impact.
          </li>
          <li>
            <b>Speed and efficiency:</b> With CareerGenius.org's AI-powered resume builder, you can create a professional-looking resume in just a few minutes, without the need for extensive research or experience in resume writing. This can save you time and help you quickly get your resume in front of hiring managers.
          </li>
        </ul>
        <p>In conclusion, if you're looking for a resume writing service that leverages the latest technology and resources to help you create a resume that is optimized for maximum impact, CareerGenius.org is the best choice. With its AI-powered resume builder, you can create a professional-looking resume in just a few minutes, and increase your chances of landing your dream job. So, why wait? Get started with CareerGenius.org today and take the first step towards a successful career.</p>
      </div>
        <h2 className="mb-4">Why Choose CareerGenius.org for Your Resume Writing Services?</h2>
        <ul className="mb-5">
          <li>AI-powered technology for optimized resumes</li>
          <li>Customized templates to fit your unique needs and job target</li>
          <li>Affordable pricing options to meet your budget</li>
          <li>Dedicated customer support for all of your questions and concerns</li>
        </ul>
        <h2 className="mb-4">Get the Best Resume Writing Services at CareerGenius.org Today!</h2>
        <p className="mb-5">
          Ready to take the first step towards your dream job? Fill out our quick and easy online form at CareerGenius.org and one of our expert resume writers will be in touch to help you create the best resume for your job search.
        </p>
        
        <Link to="/" className="btn btn-primary">
          Home
        </Link>
      </div>
    </Container>
  );
};

export default ResumeWritingServices;
