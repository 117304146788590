import React, { useState } from 'react';
import axios from './api/axios.js';
import { useParams, Link } from 'react-router-dom';

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    axios.post('/reset-password', { token, password })
      .then(response => {
        setMessage('Password has been reset successfully');
        setResetSuccess(true);
      })
      .catch(error => setMessage('Error resetting password'));
  };

  return (
    <div className="container">
      <h2>Reset Password</h2>

      {resetSuccess ? (
        <div>
          <p>{message}</p>
          <Link to="/login" className="btn btn-primary">Login</Link>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">New Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="form-control"
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit" className="btn btn-primary">Reset Password</button>
        </form>
      )}

      {message && !resetSuccess && <p>{message}</p>}
    </div>
  );
};

export default ResetPassword;
