import React, { useState,useContext,useEffect } from 'react';
import axios from '../api/axios';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from "../context/AuthProvider";
import { Container } from 'react-bootstrap';

const ReviewForm = ({pastedEmail,currentStep,setPastedEmail,setCurrentStep,setPasted, handleNext}) => {

  const { auth,setAuth } = useContext(AuthContext);

  const handleChange = (event) => {
  
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNext();
  };

  const buildEmail = (event) => {
    event.preventDefault();
    setCurrentStep(2);
  };

  const pastedEmailSubmit = (event) => {
    event.preventDefault();
    setPasted(true);
    setCurrentStep(6);
    //console.log(pastedEmail);
  };
  

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'CopyOrBuildEmail'             ,'Page'              ,'Landed on CopyOrBuildEmail page');
  }, []);

  return (

    <div>
      <div className="mx-auto">
        <h3 className="text-center">Summarize what you want to say in the Email</h3>
        <p className="text-center"></p>
        <div className="d-flex justify-content-center">
          <textarea
            value={pastedEmail}
            style={{ height: '300px', width: '800px', maxHeight: '50%', maxWidth: '100%' }}
            onChange={(e) => setPastedEmail(e.target.value)}
          />
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <button type="button" className="text-center btn btn-secondary" onClick={pastedEmailSubmit}>
            Next
          </button>
        </div>
        <br />
        {/* <h3 className="text-center">Or Build your email from scratch</h3>
        <br />
        <div className="d-flex justify-content-center">
          <button type="button" className="text-center" onClick={buildEmail}>
            Build New Email
          </button>
        </div>
        <br /> */}
      </div>
    </div> 
  );
};

export default ReviewForm;
