import React, { useState } from 'react';


const SkillsForm = ({ addSkills,handleNext, handleBack }) => {
  const [skills, setSkills] = useState('');

  const handleChange = (event) => {
    setSkills(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addSkills(skills);
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="skills">Skills:</label>
      <textarea
        id="skills"
        name="skills"
        value={skills}
        onChange={handleChange}
      />
      <br />
      <button type="button" onClick={handleBack}>
        Back
      </button>
      <button type="submit">Next</button>
    </form>
  );
};

export default SkillsForm;
