import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
import { Container } from 'react-bootstrap';

export const HowToWriteAStrongResume = () => {
const { auth } = useContext(AuthContext);

useEffect(() => {
storeActivity(auth.sessionId, 'Page Landing', 'Cover Letter Tips', 'Page', 'Landed on Cover Letter Tips page');
}, []);

return (
<Container>
<div className="cover-letter-tips-container text-center">
<h1 className="mb-4">Get the Best Cover Letter Writing Tips at CareerGenius.org</h1>
<p className="mb-5">
Are you searching for professional and effective cover letter writing tips? Look no further! At CareerGenius.org, our team of experienced writers provide top-notch tips to help you stand out from the competition. Our AI technology optimizes your cover letter for the specific job you're applying for, increasing your chances of landing an interview.
</p>
<div>
      <h3>Tips for Writing a Strong Resume</h3>
      <ul>
        <li>
          Tailor your resume to the job you're applying for: Make sure to
          highlight the skills, experiences, and qualifications that are relevant
          to the position you're applying for.
        </li>
        <li>
          Use keywords: Many companies use applicant tracking systems (ATS) to
          screen resumes. To ensure your resume makes it past the ATS, make sure
          to include keywords that match the job description.
        </li>
        <li>
          Highlight your achievements: Instead of just listing your
          responsibilities, focus on what you accomplished in each role. Use
          quantifiable data to demonstrate your impact.
        </li>
        <li>
          Keep it concise: Aim for a one-page resume, and make sure to
          eliminate any irrelevant information.
        </li>
        <li>
          Use clear and concise language: Avoid using complex language or
          industry jargon. Stick to clear and concise language that is easy to
          understand.
        </li>
        <li>
          Proofread: Make sure to thoroughly proofread your resume for typos,
          grammar errors, and formatting issues.
        </li>
        <li>
          Customize your resume for each job application: Each job is unique, so
          make sure to tailor your resume to each individual application.
        </li>
      </ul>
      <p>
        By following these tips, you can create a strong resume that will help
        you stand out from the competition and increase your chances of landing
        your dream job.
      </p>
    </div>
<h2 className="mb-4">Why Choose CareerGenius.org for Your Cover Letter Writing Tips?</h2>
<ul className="mb-5">
<li>Expert advice from experienced writers</li>
<li>AI-powered optimization technology for increased chances of success</li>
<li>Tailored tips to fit your unique job target and needs</li>
<li>Dedicated customer support for all of your questions and concerns</li>
</ul>
<h2 className="mb-4">Get the Best Cover Letter Writing Tips at CareerGenius.org Today!</h2>
<p className="mb-5">
Ready to take your cover letter writing to the next level? Visit CareerGenius.org for expert cover letter writing tips and start crafting the perfect cover letter for your job search today.
</p>

<Link to="/" className="btn btn-primary">
Home
</Link>
</div>
<h3>Example Resume</h3>
<div className="resume-container">
      <h1 className="resume-title">Your Name</h1>
      <div className="resume-contact-info">
        <p><strong>Email:</strong> yourEmail@email.com</p>
        <p><strong>Phone:</strong> (555) 555-5555</p>
        <p><strong>Location:</strong> Your City, Your State</p>
      </div>
      <h2 className="resume-section-title">Objective</h2>
      <p className="resume-section-content">
        To secure a challenging and rewarding position in a dynamic and fast-paced environment where I can utilize my skills and experience to make a positive contribution to the company.
      </p>
      <h2 className="resume-section-title">Education</h2>
      <div className="resume-section-content">
        <h3 className="resume-subsection-title">University Name</h3>
        <p><strong>Degree:</strong> Bachelor of Science in Field of Study</p>
        <p><strong>Graduation Date:</strong> Month Year</p>
        <p><strong>GPA:</strong> 3.5/4.0</p>
      </div>
      <h2 className="resume-section-title">Work Experience</h2>
      <div className="resume-section-content">
        <h3 className="resume-subsection-title">Company Name</h3>
        <p><strong>Position:</strong> Job Title</p>
        <p><strong>Employment Dates:</strong> Month Year - Present</p>
        <ul>
          <li>Accomplishment 1</li>
          <li>Accomplishment 2</li>
          <li>Accomplishment 3</li>
        </ul>
      </div>
      <h2 className="resume-section-title">Skills</h2>
      <div className="resume-section-content">
        <ul>
          <li>Skill 1</li>
          <li>Skill 2</li>
          <li>Skill 3</li>
        </ul>
      </div>
      <h2 className="resume-section-title">References</h2>
      <div className="resume-section-content">
        <p>Available upon request</p>
      </div>
    </div>
</Container>
);
};

export default HowToWriteAStrongResume;