import React, { useState,useEffect,useContext } from 'react';
import { UserContext } from './context/UserContext';
import AuthContext from "./context/AuthProvider";
import axios from './api/axios';
import storeActivity from './functions/storeActivity.jsx';


const LogoutButton = () => {
const { auth,setAuth } = useContext(AuthContext);



  const handleLogout = () => {
    
/*     axios.post('/logout', { //server route is a get this will need to be updated if implented
        withCredentials: true,
      })
        .then(() => {
          // Redirect the user to the login page or show a message
        })
        .catch((error) => {
          console.error(error);
        }); */
    storeActivity( auth.sessionId       ,"Page Landing"     ,'LogoutButton'             ,'Page'              ,'Landed on LogoutButton page');
    window.location.replace("/");
    localStorage.setItem('username', null);
    localStorage.setItem('credits', 0);
    localStorage.setItem('loggedIn', 0);
    localStorage.setItem('sessionId', '');
    localStorage.setItem('auth-token', '');
    localStorage.setItem('email', '');
    localStorage.setItem('user_role_type', '');
    localStorage.removeItem('');
    localStorage.removeItem('username');
    localStorage.removeItem('credits');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('auth-token');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('email');
    localStorage.removeItem('user_role_type');
    localStorage.removeItem('isVerified');
    setAuth({username:'null',credits:0,loggedIn:'0',sessionId:'',email:''});

    axios.post('/logout', { username: auth.username, sessionId: auth.sessionId },{
        headers: {'Content-Type': 'application/x-www-form-urlencoded',},
        withCredentials: true // This sends the cookie with the request
        }).then(() => {
            // Redirect the user to the login page or show a message
            })
            .catch((error) => {
            console.error(error);
            }); 

        };

  return (
    <button className="btn btn-outline-secondary " onClick={handleLogout}>Logout</button>
  );
}

export default LogoutButton;