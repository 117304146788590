import React, { useState } from 'react';

const WorkExperienceForm = ({ addWorkExperience, handleNext, handleBack }) => {
  const [workExperience, setWorkExperience] = useState([
    {
      company: '',
      position: '',
      startDate: '',
      endDate: '',
      responsibilities: '',
    },
  ]);

  const handleChange = (event, index) => {
    const { name, value } = event.target;

    setWorkExperience((prevWorkExperience) => {
      const newWorkExperience = [...prevWorkExperience];
      newWorkExperience[index] = {
        ...newWorkExperience[index],
        [name]: value,
      };    
      return newWorkExperience;
    });
    
  };

  const addWorkExperienceItem = () => {
    setWorkExperience((prevWorkExperience) => [
      ...prevWorkExperience,
      {
        company: '',
        position: '',
        startDate: '',
        endDate: '',
        responsibilities: '',
      },
    ]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addWorkExperience(workExperience);
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      {workExperience.map((experience, index) =>
(
  <div key={index}>
    <label htmlFor={`company-${index}`}>Company:</label>
    <input
      type="text"
      id={`company-${index}`}
      name="company"
      value={experience.company}
      onChange={(event) => handleChange(event, index)}
    />
    <br />
    <label htmlFor={`position-${index}`}>Position:</label>
    <input
      type="text"
      id={`position-${index}`}
      name="position"
      value={experience.position}
      onChange={(event) => handleChange(event, index)}
    />
    <br />
    <label htmlFor={`startDate-${index}`}>Start Date:</label>
    <input
      type="text"
      id={`startDate-${index}`}
      name="startDate"
      value={experience.startDate}
      onChange={(event) => handleChange(event, index)}
    />
    <br />
    <label htmlFor={`endDate-${index}`}>End Date:</label>
    <input
      type="text"
      id={`endDate-${index}`}
      name="endDate"
      value={experience.endDate}
      onChange={(event) => handleChange(event, index)}
    />
    <br />
    <label htmlFor={`responsibilities-${index}`}>Responsibilities:</label>
    <textarea
      id={`responsibilities-${index}`}
      name="responsibilities"
      value={experience.responsibilities}
      onChange={(event) => handleChange(event, index)}
    />
    <br />
    <button type="button" onClick={addWorkExperienceItem}>
      Add Company
    </button>
  </div>
))}
<button type="button" onClick={handleBack}>
  Back
</button>
<button type="submit" >
  Next
</button>
</form>
);
};

export default WorkExperienceForm;