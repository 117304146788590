import React,{useState,useEffect,useContext} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LogoutButton from './LogoutButton.jsx';
import AuthContext from "./context/AuthProvider";
import { Dropdown,Button } from 'react-bootstrap';
import "./ToolBar.css";
const ToolBar = () => {
  const { auth,setAuth } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState('');
  const [loggedIn, setLoggedIn] = useState('');

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
      setIsSubscribed(true);
      }
  }, []);
  

  useEffect(() => {
     
    setUsername(localStorage.getItem('username'));
    setCredits(localStorage.getItem('credits'));
    setLoggedIn(localStorage.getItem('loggedIn'));
  });
  return(
    <nav style={{
      marginLeft: 'auto',
      display: 'flex',
    }}>
   { !isSubscribed ? (auth.loggedIn==1 ? <Button variant=" btn primary"  as={Link} to="/subscribe" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#333',
        textDecoration: 'none',
        marginRight: '20px',
      }}>Buy Subscription</Button> : null) : null}
      {auth.loggedIn==1 ? <Button  variant=" btn primary"  as={Link} to="/account" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#333',
        textDecoration: 'none',
        marginRight: '20px',
      }}>Account</Button> : null}
      
      {auth.loggedIn==1 ? <Dropdown >
        <Dropdown.Toggle variant=" btn primary"  id="dropdown-basic">
          Genius AI Tools Dropdown
        </Dropdown.Toggle>
      <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/CoverLetterBuilder">Coverletter Genius</Dropdown.Item>
          {/* <Dropdown.Item as={Link} to="/ResumeBuilder">Resume Genius</Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/ResumeTools">Resume Genius Tools</Dropdown.Item>
          <Dropdown.Item as={Link} to="/EmailBuilder">Thank you Email Genius</Dropdown.Item>
          <Dropdown.Item as={Link} to="/SocialPostBuilder">SocialPost Genius</Dropdown.Item>
          <Dropdown.Item as={Link} to="/InterviewPrep">Interview Prep Genius</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> : 
      <Dropdown >
      <Dropdown.Toggle variant=" btn primary"  id="dropdown-basic">
      Genius Genius Tools Dropdown
    </Dropdown.Toggle>
  <Dropdown.Menu>
      <Dropdown.Item as={Link} to="/loginform">Coverletter Genius</Dropdown.Item>
      <Dropdown.Item as={Link} to="/loginform">Resume Genius</Dropdown.Item>
      <Dropdown.Item as={Link} to="/loginform">Thank you Email Genius</Dropdown.Item>
      {/* <Dropdown.Item as={Link} to="/loginform">SocialPost Genius</Dropdown.Item> */}
    </Dropdown.Menu>
  </Dropdown>}
    </nav>
  );
};

export default ToolBar;
